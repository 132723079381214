<template>
  <view-container name="security">
    <view-header>
      <custom-header :router-back="'/settings'">
        <template v-slot:default>
          <svg-icon size="size24x24" :name="'icoArrowLeft'" />Security &
          Confidentiality
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <div data-vue-name="Settings" class="settings">
        <ul class="settings__nav">
          <li v-for="item in settingsNav" :key="item.id">
            <router-link :to="item.url">
              <svg-icon size="size24x24" class-name="me-3" :name="item.img" />
              <span>
                {{ item.title }}
              </span>
              <svg-icon size="size20x20" class-name="ms-auto" :name="'icoArrowRightBlue'" />
            </router-link>
          </li>
        </ul>
      </div>
    </view-content>
  </view-container>
</template>

<script>
export default {
  name: 'Settings',
  data() {
    return {
      settingsNav: [
        {
          id: 1,
          title: 'Reset Password',
          url: '/settings/security/reset-password',
          img: 'icoResetPwd',
        },
      ],
    }
  },
  methods: {
    logout() {
      console.log('logout')
      this.$store.dispatch('logout')
      this.$router.push({ name: 'authorization' })
    },
  },
}
</script>

<style lang="stylus">

.security-view {
  .view-content {
    padding 0 16px !important
  }
  .container-fluid {
    padding 0
  }
}

.settings__nav li a {
  display flex
  align-items center
  margin 0 22px 0 18px
  padding 25px 0
  font-size: 16px;
  line-height: 140%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  color: #D8D8D8;
  text-decoration none

  .size20x20 {
    margin-right 18px
  }


}
.settings__nav li:last-child a {
  border none
}
</style>
